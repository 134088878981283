@import '../../styles/variables';
@import '../../styles/webkit-scrollbar-styles';

.selected-note {
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: $left-width;
  position: absolute;
  right: 0;
  top: 0;

  .note-details {
    align-items: stretch;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-evenly;
    overflow: hidden;
  }

  .edit-note {
    background-color: $background-color-medium;
    display: flex;
    flex: 1;
    flex-flow: column;
    overflow: auto;
    padding: 0;

    textarea {
      background: $background-color-medium;
      border: none;
      color: $text-color;
      height: calc(100% - #{$padding * 4});
      margin: 0;
      padding: $padding * 2;
      resize: none;
      width: calc(100% - #{$padding * 4});

      @include webkit-scrollbar($background-color-medium);

      &:focus {
        outline: none;
      }
    }
  }

  .view-note {
    background: $background-color-light;
    box-sizing: border-box;
    flex: 1;
    overflow: auto;

    .markdown-body {
      padding: $padding * 2;
      width: calc(100% - #{$padding * 4});
    }
  }
}