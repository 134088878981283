@import '../../styles/variables';

$category-width: calc(100% - 120px);

.note-header {
  background: $background-color-dark;
  box-sizing: border-box;
  padding: $padding ($padding * 2);
  position: relative;

  input {
    background: $background-color-dark;
    border: none;
    color: $text-color;
    display: block;
    font-size: 2em;
    font-weight: 600;
    width: $category-width;
  }

  .category select {
    background: $background-color-dark;
    border: none;
    color: $text-color;
    cursor: pointer;
    width: $category-width;
  }

  .category input {
    font-size: 1em;
    font-style: italic;
    font-weight: 300;
  }

  .delete-note {
    background: none;
    border: 1px dashed $delete-color;
    bottom: $padding;
    color: $delete-color;
    opacity: 0.7;
    position: absolute;
    right: $padding;
    width: $toggle-width;

    &:hover {
      background: $delete-color;
      border-style: solid;
      color: #fff;
      cursor: pointer;
    }
  }
}