@import '../../styles/variables';
@import '../../styles/webkit-scrollbar-styles';

$header-height: 145px;
$footer-height: 55px;

.notes-list {
  background-color: $background-color-dark;
  bottom: 0;
  left: 0;
  overflow: auto;
  position: absolute;
  top: 0;
  width: $left-width;

  .header {
    height: $header-height;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .scrollable-content {
    bottom: $footer-height;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    right: 0;
    top: $header-height;
    width: 100%;

    @include webkit-scrollbar($background-color-dark);
  }

  .footer {
    bottom: 0;
    box-sizing: border-box;
    height: $footer-height;
    left: 0;
    padding-top: 15px;
    position: absolute;
    right: 0;
    text-align: center;
    width: 100%;
  }

  .create-note {
    background: $button-background-color;
    border: none;
    color: $button-text-color;
    cursor: pointer;
    display: block;
    font-size: 1em;
    font-weight: bold;
    padding: $padding 0;
    text-align: center;
    width: 100%;

    &:hover {
      background: mix(#000000, $button-background-color, 20);
    }
  }

  .category-selector {
    display: block;
    margin: $padding;
    width: calc(100% - #{$padding * 2});
  }

  .search {
    border: none;
    display: block;
    margin: $padding;
    padding: ($padding / 4) ($padding / 2);
    width: calc(100% - #{$padding * 3});
  }

  .note-category-heading {
    background: darken($background-color-dark, 2%);
    display: block;
    padding: 5px $padding;
    font-size: 0.8em;
    font-style: italic;
  }

  .note-preview {
    cursor: pointer;
    padding: $padding;

    &:nth-of-type(odd) {
      background: lighten($background-color-dark, 2%);
    }

    &:hover, &.selected {
      background: $selected-note-highlight;
      color: $background-color-dark;
    }

    .note-title {
      font-weight: bold;
    }

    .note-category {
      font-size: 0.7em;
      font-weight: lighter;
    }
  }
}