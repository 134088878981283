@import '../../styles/variables';

.layout-toggle {
  display: flex;
  list-style: none;
  margin: 0;
  position: absolute;
  right: $padding;
  top: $padding;
  width: $toggle-width;

  li {
    background: $toggle-background;
    cursor: pointer;
    flex: 1;
    padding: 5px 0;
    text-align: center;

    &:hover, &.selected {
      background: $toggle-background-selected;
    }
  }
}