@import 'variables.scss';
@import 'webkit-scrollbar-styles';
@import 'utils.scss';

body {
  color: $text-color;
  font-family: $font-family;
  font-size: $font-size;

  * {
    font-family: $font-family;
    font-size: $font-size;

    @include webkit-scrollbar($background-color-light);
  }

  .secondary-button {
    background: $secondary-button-background;
    color: $secondary-button-text-color;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    margin: 2px;
    outline: none;
    padding: 5px $padding;
    text-decoration: none;

    &:hover {
      background: lighten($secondary-button-background, 10%);
    }
  }
}