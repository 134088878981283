@import "../../node_modules/nord/src/sass/nord.scss";

$font-family: 'fira-mono', monospace;
$font-size: 14px;
$text-color: $nord6;
$delete-color: $nord11;
$padding: $font-size;

$background-color-dark: $nord0;
$background-color-medium: $nord1;
$background-color-light: $nord6;

$button-background-color: $nord10;
$button-text-color: $text-color;

$left-width: 250px;

$selected-note-highlight: $nord4;
$toggle-background: $nord9;
$toggle-background-selected: $nord10;

$secondary-button-background: $nord3;
$secondary-button-text-color: $nord6;

$import-left-side-background: $nord10;

$toggle-width: 80px;